import { Typography, Paper, Box } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const styles = {
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
        width: '100%'
    },

    paper: {
        padding: '20px',
        maxWidth: 'sm',
        margin: '50px auto 0 auto',
        textAlign: 'center'
    },

    shoppingCartIcon: {
        fontSize: '200px',
        color: '#2196f3'
    }

};

export default function ThankYou() {
    return (
        <Box sx={styles.box}>
            <Paper elevation={3} sx={styles.paper}>
                <ShoppingCartIcon sx={styles.shoppingCartIcon} />
                <Typography variant="h5" gutterBottom>
                    Thank You for Your Purchase!
                </Typography>
                <Typography variant="body1">
                    We are processing your order.
                </Typography>
                <Typography variant="body1">
                    Your subscription details will be updated shortly.
                </Typography>
            </Paper>
        </Box>
    );
}