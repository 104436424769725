import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";


export default function ConsentForm({ domain, state }) {
  return (
      <Box sx={{ p: 4 }}>
        <Paper elevation={4} sx={{ p: 4, borderRadius: 1 }}>
          <Typography variant="body1">
            You must agree to the <a target="_blank" rel="noreferrer" href="https://www.oxygenxml.com/aipositron/user_terms.html">Terms and Conditions</a> and
            to the <a target="_blank" rel="noreferrer" href="https://www.oxygenxml.com/privacy_policy.html">Privacy Policy</a> before continuing.
          </Typography>
          <form
              action={"https://" + domain + "/continue?state=" + encodeURIComponent(state)}
              method="post"
          >
            <Box mt={2}>
              <FormControlLabel
                  control={
                    <Checkbox name="confirm" value="yes" color="primary" />
                  }
                  label="I agree"
              />
            </Box>
            <Button
                type="submit"
                variant="contained"
                size="large"
                color="success"
                sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </form>
        </Paper>
      </Box>
  );
}
