import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';

import CurrentPlanInfo from './CurrentPlanInfo';
import SubscriptionPlansGrid from './SubscriptionPlansGrid';

import { FAILED_RETREIVE_SUBSCRIPTION_PLAN_MSG, DETECTING_SUBSCRIPTION_PLAN_MSG } from '../Constants';

const Subscriptions = () => {

  const [currentPlan, setCurrentPlan] = useState({planName: DETECTING_SUBSCRIPTION_PLAN_MSG});
  const [subscriptionPlanError, setSubscriptionPlanError] = useState(null);

  useEffect(() => {
    const getSubscriptionPlan = async () => {
      const headers = {
        "Content-Type": "text/plain",
      };

      const response = await fetch('/api/subscription/plan', {
        method: 'GET',
        headers
      });

      if (response.ok) {
        const data = await response.json();
        setCurrentPlan(data);
      } else {
        setSubscriptionPlanError(FAILED_RETREIVE_SUBSCRIPTION_PLAN_MSG);
        console.log(`${FAILED_RETREIVE_SUBSCRIPTION_PLAN_MSG}. Response status code: ${response.status}.`);
      }
    };
    getSubscriptionPlan();
  }, []);

  return (
    <Container sx={{ paddingTop: 4 }}>

      <Typography variant="h4" gutterBottom>Subscription Plans</Typography>

      <CurrentPlanInfo subscriptionPlanError={subscriptionPlanError} currentPlan={currentPlan} />

      <SubscriptionPlansGrid currentPlan={currentPlan} />

    </Container>
  );
};

export default Subscriptions;
