import CustomAppBar from "../components/PositronAppBar.jsx";
import {Card, CardContent} from "@mui/material";
import ConsentForm from "../components/ConsentForm.jsx";
import {handleLogout} from "../util/logout.js";
import React from "react";
import {useSearchParams} from "react-router-dom";
import {useSpringParam} from "../util/useSpringParam.jsx";

export default function ConsentPage() {
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');
  const domain = useSpringParam('domain');

  return <>
    <CustomAppBar loggedIn={false} onLogout={handleLogout}/>
    <Card>
      <CardContent>
        <ConsentForm domain={domain} state={state}/>
      </CardContent>
    </Card>
  </>;
}