import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React from 'react';
import { useCSRF } from "../util/useCSRF.jsx";
import Alert from "@mui/material/Alert";

export default function EmailValidation({user}) {

  const csrf = useCSRF();
  const [showOKResult, setShowOKResult] = React.useState(false)
  const [showFailedResult, setShowFailedResults] = React.useState(false)
  
 
  function reloadPage() {
    window.location.reload();
  } 

  function resendEmail() {
    var headers = {
      "Content-Type": "application/json",
      [csrf.csrfHeader]: csrf.csrfToken
    }
    
    fetch('/api/users/resendEmail', {
      method: 'POST',
      headers
    }).then(response => {
      if (response.ok) {
        setShowOKResult(true)
        setTimeout(() => {
          setShowOKResult(false);
        }, 3000);

      } else {
        setShowFailedResults(true)
        setTimeout(() => {
          setShowFailedResults(false);
        }, 3000);
      }

    });
  }

return (<>
  <Typography style={{fontWeight:'bold'}}>Verify your email</Typography><br/>
  <Typography>We sent an email to {user.email} </Typography>
  <Typography>Once verified, you can reload this page and start using the Oxygen AI Positron.</Typography>
  <Button sx={{px:0, textTransform: 'none'}} variant="text" onClick={reloadPage}>Reload page</Button>
  <br/>
  <Button sx={{px:0, textTransform: 'none'}} variant="text" onClick={resendEmail}>Resend email</Button>
  <span>
    { showOKResult ? <ResultOk/> : null }
    { showFailedResult ? <ResultFailed/> : null }
  </span>
  </>);
}

const ResultOk = () => (
  <Alert severity="success">Email sent</Alert>
  )
  
  const ResultFailed = () => (
  <Alert severity="error">Unable to send email. Try again later.</Alert>
)
