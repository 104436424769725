import { Typography, Card, CardContent, Button} from '@mui/material';

/**
 *
 * @param plan
 * @param currentPlan
 * @param allPlans
 * @returns {JSX.Element}
 * @constructor
 */
function SubscriptionPlanCard({plan, currentPlan, allPlans}) {
    /**
     * Creates the styles to be used in the component.
     */
    const styles = {

        disablePlanActionButton: {
            marginTop: 3,
            color: "#E7E3E3",
            backgroundColor: "#8AA1C1",
            cursor: "default",
            pointerEvents: "none",
            padding: "6px 16px !important",
            '&:hover': {
                backgroundColor: "#8AA1C1",
            }
        },

        planActionButton: {
            marginTop: 3,
            color: "primary",
            width: "unset",
            whiteSpace: "nowrap",
            maxWidth: "150px",
        },
    };

    let actionButton;
    const isEvaluation =  currentPlan.planName.toLowerCase() === "evaluation";
    const isSelectedPlan =  plan.planName.toLowerCase() === currentPlan.planName.toLowerCase();
    const isCancelled = currentPlan.cancelled;
    const isExpiredAndBeyondRenew = currentPlan.isExpired && !currentPlan.canBuyRenewLicense;

    if(isEvaluation || isCancelled || isExpiredAndBeyondRenew) {
        actionButton = (
          <Button variant="contained" href={`/checkout/subscribe?plan=${plan.id}`} sx={styles.planActionButton}>
              Subscribe
          </Button>);
    } else {
        if(isSelectedPlan) {
            let canBuyRenew = currentPlan.canBuyRenewLicense;
            let buttonStyle = canBuyRenew ? styles.planActionButton : styles.disablePlanActionButton;
            let buttonId = canBuyRenew ? plan.id + "-renew-button" : plan.id + "-current-plan-button";
            let buttonText = canBuyRenew ? 'Renew' : 'Current plan';
            let href = canBuyRenew ? '/checkout/renew' : '#';
            actionButton = (<Button variant="contained" id={buttonId} sx={buttonStyle} href={href}>{buttonText}</Button>);
        } else {
            if (isInferiorPlan(plan, currentPlan, allPlans)) {
                actionButton = (
                   <Button variant="contained" id={plan.id + "-disabled-subscribe-button"} sx={styles.disablePlanActionButton}>
                       Subscribe
                   </Button>
                );
            } else {
                actionButton = (
                  <Button variant="contained" id={plan.id + "-subscribe-button"}
                          sx={styles.planActionButton} href={`/checkout/upgrade?plan=${plan.id}`}>
                      Upgrade
                  </Button>
                );
            }
        }
    }

    const isActivePlan = !(currentPlan.isExpired || isCancelled);

    return (
        <Card variant="outlined" sx={{ border: isSelectedPlan && isActivePlan ? '2px solid gray' : '1px solid light-gray' }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h6" sx = {{textTransform: "uppercase"}}>{plan.planName}</Typography>
                <Typography variant="body1" color="textSecondary" sx={{ marginTop: 1, textAlign: 'center' }}>
                    {plan.description}
                </Typography>
                <Typography variant="h5" color="primary" sx={{ marginTop: 2 }}>
                    {plan.price}
                </Typography>
                <Typography variant="body2" color="textSecondary">per month</Typography>
                <Typography variant="body2" sx={{ marginTop: 3 }}>
                    Monthly credits: {Number(plan.credits).toLocaleString()}
                </Typography>

                {actionButton}
            </CardContent>
        </Card>
    );
}

function isInferiorPlan(plan, currentPlan, allPlans) {
    const plans = allPlans.map(p => p.planName.toLowerCase())
    let planIndex = plans.indexOf(plan.planName.toLowerCase());
    let currentPlanIndex = plans.indexOf(currentPlan.planName.toLowerCase());
    return planIndex < currentPlanIndex;
}

export default SubscriptionPlanCard;