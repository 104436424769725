import { useEffect, useState } from 'react';

export function useSpringParam(name) {
  const [paramValue, setParamValue] = useState(null);

  useEffect(() => {
    const metaElement = document.querySelector(`meta[name=positron-${name}]`);
    setParamValue(metaElement.content);
  }, [name]);
  return paramValue;
}
