import { Card, CardContent,  Alert, AlertTitle} from "@mui/material";
import React from "react";
import CustomAppBar from "../components/PositronAppBar.jsx";
import { useSpringParam } from "../util/useSpringParam.jsx";


export default function CountryPage() {
    const countryName = useSpringParam('country');
return (<>
  <CustomAppBar loggedIn={false}/>
  <Card>
    <CardContent>
    <Alert severity="error">
    <AlertTitle><b>Access Denied</b></AlertTitle>
    Access is not allowed for users in {countryName}.</Alert>
    </CardContent>
  </Card>
</>
);
}