import { useCookies } from 'react-cookie';
import {Typography, Button} from "@mui/material";

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookie_policy"]);
  const giveCookieConsent = () => {
    setCookie("cookie_policy", "displayed", { path: "/", secure: true});
  };

  const styles = {
    cookieNotificationBanner: {
      position: 'fixed', 
      textAlign: 'center',
      padding: '.5em .5em',
      color: 'white', 
      backgroundColor: '#323232', 
      bottom: 0, 
      width: '100%',
      zIndex: 999 
    },

    aboutCookiesLinkStyle: {
      color: '#A0C1E2'
    },

    acceptCookiesButton: {
      padding: 'revert', 
      textTransform: 'none', 
    }
};

  return !cookies.cookie_policy && (
     <Typography variant="body1" sx={styles.cookieNotificationBanner}>
      <span>Our website uses cookies.{" "}<a style={styles.aboutCookiesLinkStyle} href={"https://www.oxygenxml.com/about_cookies.html"}> Find out more about cookies.</a></span>
      {" "}<Button variant="contained" sx={styles.acceptCookiesButton} onClick={giveCookieConsent}>I understand. Hide this message.</Button>
     </Typography>
  );
};

export default CookieConsent;