import React, { useEffect, useState } from 'react';
import { Typography } from "@mui/material";

export default function UsageReport() {
    const [status, setStatus] = useState(null);

    useEffect(() => {
        const generateReport = async () => {
            var headers = {
                "Content-Type": "application/json",
            }

            await fetch('/api/v2/usage', {
                method: 'GET',
                headers
            })
            .then(response => response.json())
            .then(data => setStatus(data));
        };
        generateReport();
    }, []);

    if (!status) {
        return <Typography>Generating usage report...</Typography>;
    }

    return (
        <Typography>{status.message}</Typography>
    );

}