import {Grid, Typography} from "@mui/material";
import React from "react";
import CustomAppBar from "../components/PositronAppBar.jsx";
import {useSpringParam} from "../util/useSpringParam.jsx";


export default function CustomErrorPage() {
  const errorCode = useSpringParam('error-code');
  const errorMessage = useSpringParam('error-message');
  const styles = {
    errorContainer: {
      marginTop: "4em",
      flex: "1 1 auto",
      display: "flex",
      flexDirection:"row",
      justify: "center",
      alignItems: "center",
      alignContent: "center"
    },

    errorMessage: {
      textAlign: "center",
      marginBottom: "10px",
      fontSize: "31px",
      textTransform: "uppercase"
    },

    errorCodeContainer: {
      position: "relative",
      textAlign: "center"
    },

    errorCodeTextContainer: {
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      width: "100%",
      height: "74%",
      position: "absolute",
      top: "20px",
      left: 0
    },

    errorCodeText: {
      color: "white",
      fontSize: "172px"
    },


    goToHomeTextContainer: {
      marginTop: "-130px",
      zIndex: 1000,
      textAlign: "center",
    },

    goToHomeText: {
      textDecoration: "none",
      color: "#3B86FF",
      fontSize: "31px"
    }
  };

  return (<>
      <CustomAppBar loggedIn={false} hasError={true}/>
      <Grid>
        <br/>
        <br/>
        <Grid container sx={styles.errorContainer}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={styles.errorMessage}>{errorMessage}</Typography>
          </Grid>
          <Grid item xs={12} sx={styles.errorCodeContainer}>
            <img src="static/images/error-container.png" alt="Error container"/>
            <Grid container sx={styles.errorCodeTextContainer}>
              <Typography variant="h1" sx={styles.errorCodeText}>{errorCode}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={styles.goToHomeTextContainer}>
            <Typography variant="h4"><a href="/" style={styles.goToHomeText}>Go to Homepage</a></Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}