import UsageReport from './UsageReport';
import { Typography, Paper } from '@mui/material';
import { DETECTING_SUBSCRIPTION_PLAN_MSG, CURRENT_PLAN_LABEL, EVALUATION_PLAN } from '../Constants';

/**
 * Get the current plan info component to render.
 * 
 * @param {string} subscriptionPlanError An error message. May be falsy. 
 * @param {*} currentPlan                The current plan.
 * 
 * @returns the current plan info component to render.
 */
const CurrentPlanInfo = ({subscriptionPlanError, currentPlan}) => {
    const currentPlanName = currentPlan.planName;
    const isExpired = currentPlan.isExpired;
    const isCancelled = currentPlan.cancelled;
    const inactivePlan = isExpired || isCancelled;
    const isBoughtSubscription = currentPlanName !== DETECTING_SUBSCRIPTION_PLAN_MSG && currentPlanName !== EVALUATION_PLAN && !inactivePlan;
    
    let planState;
    let extraInfo;
    if(isCancelled){
		planState = 'was cancelled';
		extraInfo = 'If you wish to continue using Oxygen AI Positron, please purchase a new subscription.';
	} else if(isExpired) {
		planState = 'is no longer active';
	} else {
		planState = 'is currently active';
	}
    const currentPlanActiveStateMessage =
    <>
        <span>The <span style={{ fontWeight: "bold" }}>{currentPlanName}</span> plan {planState}.</span>
        {isCancelled && <><br/><br/><span>{extraInfo}</span></>}
    </>;

    return (
        <Paper elevation={2} sx={{ padding: 2, marginBottom: 3 }}>
            <Typography variant="h5">{CURRENT_PLAN_LABEL}</Typography>
            <br />
            <Typography variant="body1">
                {subscriptionPlanError ? subscriptionPlanError : (
                    currentPlanName === DETECTING_SUBSCRIPTION_PLAN_MSG ? `${DETECTING_SUBSCRIPTION_PLAN_MSG}...` : currentPlanActiveStateMessage)
                }
            </Typography>
			{!isCancelled && <br/>}
			{!isCancelled && <UsageReport />}
            {
                !inactivePlan && (<Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>
                    <a href="/home#credits" style={{ fontSize: '1em' }}>
                        What are Positron credits?
                    </a>
                </Typography>)
            }
            {
                isBoughtSubscription && (
                    <>
                        <br />
                        <Typography variant="body1">
                            Automatic renewal is {currentPlan.isAutoRenew ? 'on' : 'off'}.
                        </Typography>
                    </>
                )
            }
            
        </Paper>
    );
};

export default CurrentPlanInfo;