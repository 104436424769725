import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';

import SubscriptionPlanCard from './SubscriptionPlanCard';

import { FAILED_RETREIVE_ALL_SUBSCRIPTION_PLANS_MSG } from '../Constants';

const SubscriptionPlansGrid = ({currentPlan}) => {
    const [allSubscriptionPlans, setAllSubscriptionPlans] = useState(null);
    const [allSubscriptionPlansError, setAllSubscriptionPlansError] = useState(null);

    useEffect(() => {
        const getAllSubscriptionPlans = async () => {
            const headers = {
                "Content-Type": "text/plain",
            };

            const response = await fetch('/api/subscription/plans', {
                method: 'GET',
                headers
            });

            if (response.ok) {
                const data = await response.json();
                setAllSubscriptionPlans(data);
            } else {
                setAllSubscriptionPlansError(FAILED_RETREIVE_ALL_SUBSCRIPTION_PLANS_MSG);
                console.log(`${FAILED_RETREIVE_ALL_SUBSCRIPTION_PLANS_MSG}. Response status code: ${response.status}.`);
            }
        };
        getAllSubscriptionPlans();
    }, []);

    return (
        <>
          {allSubscriptionPlansError ? <Typography variant="body1">{allSubscriptionPlansError}</Typography> : 
             allSubscriptionPlans && (
              <Grid container spacing={3} marginTop={1} justifyContent="center">
                {allSubscriptionPlans.map((plan, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index} >
                    <SubscriptionPlanCard
                      plan={plan}
                      currentPlan={currentPlan}
                      allPlans={allSubscriptionPlans} />
                  </Grid>
                ))}
              </Grid>
            )
          }
        </>
    );
};

export default SubscriptionPlansGrid;