import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { HOME_PATH } from "../Constants.js";
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import Popover from '@mui/material/Popover';
import UserAvatar from './UserAvatar.jsx';

export default function CustomAppBar({loggedIn, onLogout, user, hasError}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    onLogout();
  }

  function handleLogin() {
    // Navigate to '/web-login' to login
    window.location.href = '/web-login';
  }
  
  return (
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
          <Link to={user ? HOME_PATH : '/'} style={{ display: 'flex', textDecoration: 'none', color: 'inherit', alignItems: 'center' }}>
            <IconButton edge="start" color="inherit" aria-label="menu" disableRipple>
              <img src="static/images/AIPositron32.png" alt="Positron Icon" />
            </IconButton>
            <Typography variant="h6" component="div">
              Oxygen AI Positron
            </Typography>
          </Link>
          <Box sx={{ flexGrow: 1 }}/>
          {loggedIn && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }} onClick={handleMenu}>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} >
                    {user.full_name}
                  </Typography>
                  <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                      disableRipple
                      >
                    <UserAvatar userName={user.full_name} userIcon={user.picture} />
                  </IconButton>
                  </Link>
                <Popover
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                  <MenuItem onClick={handleLogout}>Log out</MenuItem>
                </Popover>
              </div>
          )}
          {
            !!!loggedIn && !hasError && (<MenuItem onClick={handleLogin}><b>Login</b></MenuItem>)
          }
        </Toolbar>
      </AppBar>
  );
}
