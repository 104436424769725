import { useEffect, useState } from 'react';

export function useCSRF() {
  const [csrfHeader, setCsrfHeaderValue] = useState(null);
  const [csrfToken, setCsrfParamValue] = useState(null);

  useEffect(() => {
    const metaElement = document.querySelector(`meta[name=_csrf]`);
    setCsrfParamValue(metaElement.content);
  }, []);

  useEffect(() => {
    const metaElement = document.querySelector(`meta[name=_csrf_header]`);
    setCsrfHeaderValue(metaElement.content);
  }, []);

  return {csrfHeader: csrfHeader, csrfToken: csrfToken};
}
