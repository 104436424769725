import React from 'react';
import MainPage from "./pages/MainPage.jsx";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ConsentPage from "./pages/ConsentPage.jsx";
import CustomErrorPage from './pages/CustomErrorPage.jsx';
import CountryPage from './pages/CountryPage.jsx';
import Home from './components/Home.jsx';
import Subscriptions from './components/Subscriptions.jsx';
import ThankYou from './components/ThankYou.jsx';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<MainPage />}>
            <Route path='/home' element={<Home />} />
            <Route path='/subscriptions' element={<Subscriptions />} />
            <Route exact path="/checkout/thank-you" element={<ThankYou />} />
          </Route>
          <Route exact path="/public/consent" element={<ConsentPage />} />
          <Route exact path="/public/deniedCountry" element={<CountryPage />} />
          <Route exact path="*" element={<CustomErrorPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
