import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardContent, Container, Grid, Typography, List, ListItem } from "@mui/material";
import { BENEFITS } from '../Constants';

const styles = {
    li: {
        display: 'list-item',
         padding: 0.1
    }
  };

export default function Home() {

    const { hash } = useLocation();
    useEffect(() => {
      if (hash === '#credits') {
        const element = document.querySelector(hash);
        if (element) element.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    }, [hash]);

    return <>
        <Container>

            <Container sx={{paddingTop:"2em"}}>
                <Typography variant="h5" sx={{paddingBottom:"0.1em"}}>What is Oxygen AI Positron? </Typography>
                <Typography>
                    The Oxygen AI Positron service uses the OpenAI platform to help technical documentation writers with features
                    such as content generation, change suggestions, and translation capabilities. The service is accessed from within
                    Oxygen XML Editor through the <a href="https://www.oxygenxml.com/doc/help.php?product=editor&amp;platform=standalone&amp;pageId=ai_positron" target="_blank" rel="noreferrer"> AI Positron Assistant add-on</a>.
                </Typography>
            </Container>

            <Container sx={{paddingTop:"3em"}}>
                <Typography sx={{ textAlign: "center", paddingBottom: "0.5em", fontSize: "1.75em" }}>User Benefits</Typography>
                <Grid container spacing={3}>
                    {BENEFITS.map((value) => (
                        <Grid key={value.id} item sm={4}>
                            <Card sx={{ minWidth: 275, border:"none", boxShadow:"none" }}>
                                <CardContent>
                                    <Typography variant="h5" sx={{ textAlign: "center", paddingBottom:"0.1em" }}><img src={value.image} alt={value.title}/></Typography>
                                    <Typography variant="h5" sx={{ textAlign: "center", paddingBottom:"0.1em" }}>{value.title}</Typography>
                                    <Typography variant="body2"> {value.content}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <Container sx={{paddingTop:"2em"}} id="credits">
                <Typography variant="h5" sx={{paddingBottom:"0.1em"}}>Credits</Typography>
                <Typography>
                    Each user has a limit to the number of requests that are sent to the AI server each month and this is managed through the use of credits.
                    The 1-month free evaluation plan includes 500,000 credits. If you have consumed all of your free credits or the evaluation plan expires,
                    the service can only be continued by purchasing a <a href="/subscriptions" style={{ fontSize: '1em' }}>subscription plan</a>.
                </Typography>
                <Typography>
                    Each invoked action consumes credits with the AI prompt associated with
        			the action, the input text (editor text, selection from the editor text, chat messages, or
       				 user input), and the AI response. 
                </Typography>
                <Typography sx={{paddingTop:"0.2em"}}>
                    Credits are calculated using a weighted model based on <a href="https://help.openai.com/en/articles/4936856-what-are-tokens-and-how-to-count-them" target="_blank" rel="noreferrer">OpenAI tokens</a>:
                </Typography>
                <List sx={{ listStyleType: 'disc',  pl: 4 }}>
                    <ListItem sx={styles.li}>1 token of gpt-3.5-turbo = 1 credit</ListItem>
                    <ListItem sx={styles.li}>1 token of gpt-4 = 30 credits</ListItem>
                    <ListItem sx={styles.li}>1 token of gpt-4-turbo = 15 credits</ListItem>
                    <ListItem sx={styles.li}>1 token of gpt-4o = 7.5 credits</ListItem>
                </List>
            </Container>

        </Container>
    </>;
}
